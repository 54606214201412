import React, { Component } from 'react'
import { Link } from "react-router-dom";
import Grid from '@mui/material/Grid';
import TwoElementsRow from '../components/TwoElementsRow'

import { ReactP5Wrapper } from "react-p5-wrapper";
import sketch from '../components/SketchEndlessDialogue';

class Home extends Component {
    render() {
        return(
            <div className="mainContent">
                
                {/* socials */}
                <Grid padding={3} container spacing={2} direction="row" justifyContent="flex-end" alignItems="center"> 
                    <Grid item>
                        <a href="mailto:encapsuled.art@gmail.com" target="_blank" rel="noopener noreferrer">
                            <img
                                src='/images/maillogo.png'
                                alt="logo"
                                width="20px"
                            />
                        </a>
                    </Grid>
                    <Grid item>
                        <a href="https://twitter.com/encapsuled_" target="_blank" rel="noopener noreferrer">
                            <img
                                src='/images/twitterlogo.png'
                                alt="logo"
                                width="20px"
                            />
                        </a>
                    </Grid>
                    <Grid item>
                        <a href="https://discord.gg/98M2RjxruD" target="_blank" rel="noopener noreferrer">
                            <img
                                src='/images/discordlogo.png'
                                alt="logo"
                                width="20px"
                            />
                        </a>
                    </Grid>
                </Grid>

                {/* logo */}
                <Grid padding={3} container spacing={3} direction="row" justifyContent="center" alignItems="center"> 
                    <Grid item>
                        <img
                            src='/images/encapsuled_logo_v2_blackbg.png'
                            alt="logo"
                            width="50px"
                        />
                    </Grid>
                </Grid>

                {/* name */}
                <Grid container spacing={3} direction="row" justifyContent="center" alignItems="center" paddingBottom={"28px"}> 
                    <Grid item>
                        <p style={{fontSize: '20px'}}>michelangelo (encapsuled)</p>
                    </Grid>
                </Grid>

                {/* parts */}
                <Grid className="partsWrapper" container spacing={3} direction="column" justifyContent="center" alignItems="center">
                    <TwoElementsRow leftText={<span>PART&nbsp;ONE</span>} rightText="biography" isLeftLink={true} leftLink="#PART_ONE" rightLink=""/>
                    <TwoElementsRow leftText={<span>PART&nbsp;TWO</span>} rightText={<span>selected&nbsp;works</span>} isLeftLink={true} leftLink="#PART_TWO" rightLink=""/>
                </Grid>

                {/* summary */}
                <Grid container direction="row" justifyContent="center" alignItems="initial"> 
                    <Grid item className='desktopHalfMobileFullWidth' padding={3}>
                        <Grid container spacing={3} direction="column" justifyContent="center" alignItems="center">
                            <TwoElementsRow leftText={<i>selected works</i>} rightText="" isLeftLink={false} leftLink="" rightLink="" hideHr={true}/>
                            <div>&nbsp;</div>
                            <TwoElementsRow leftText="var(semic)" rightText={<span>objkt</span>} isLeftLink={true} isRightLink={true} leftLink="/varsemic" rightLink="https://objkt.com/@encapsuled?fa_contract=KT18y3uZCpeE7SamUgjGWT9VYPWx4wQkXVqY"/>
                            <TwoElementsRow leftText="Carattere" rightText={<span>ArtBlocks</span>} isLeftLink={true} isRightLink={true} leftLink="/carattere" rightLink="https://www.artblocks.io/marketplace/collections/carattere-by-encapsuled"/>
                            <TwoElementsRow leftText="Taccuini" rightText={<span>Physical x TransientLabs TRACE</span>} isLeftLink={true} isRightLink={true} leftLink="/taccuini" rightLink="https://www.transient.xyz/nfts/base/0xd1d00954d6deb4c3186cf0dce97a69fe1e249be6"/>
                            <TwoElementsRow leftText="Evolution v0" rightText={<span>MintGoldDust</span>} isLeftLink={true} isRightLink={true} leftLink="/evolutionv0" rightLink="https://www.mintgolddust.com/artwork/728/"/>
                            <TwoElementsRow leftText="Endless dialogue" rightText={<span>Manifold</span>} isLeftLink={true} isRightLink={true} leftLink="/endlessdialogue" rightLink="https://app.manifold.xyz/c/endlessdialogue"/>
                            <TwoElementsRow leftText="Loss" rightText={<span>Manifold</span>} isLeftLink={true} isRightLink={true} leftLink="/loss" rightLink="https://opensea.io/collection/encapsuledcode?search[stringTraits][0][name]=series&search[stringTraits][0][values][0]=Loss"/>
                            <TwoElementsRow leftText="Linebreak" rightText={<span>Physical</span>} isLeftLink={true} isRightLink={false} leftLink="/linebreak" rightLink=""/>
                            <TwoElementsRow leftText="Indaco" rightText={<span>NFT&nbsp;Factory</span>} isLeftLink={true} isRightLink={true} leftLink="/indaco" rightLink="https://mint.nftfactoryparis.com/indigo/mint/q3tnGCJS"/>
                            <TwoElementsRow leftText="Chronicles" rightText="VerticalCryptoArt" isLeftLink={true} isRightLink={true} leftLink="/chronicles" rightLink="https://gallery.verticalcrypto.art/token/ethereum/0x32d4be5ee74376e08038d652d4dc26e62c67f436/11"/>
                            <TwoElementsRow leftText="Manifesto" rightText="SuperRare" isLeftLink={true} isRightLink={true} leftLink="/manifesto" rightLink="https://superrare.com/0x883a2239853151b8a986a88e4f6bb66cbc2d8500/manifesto-%F0%9F%9F%A5-4"/>
                            <TwoElementsRow leftText={<span>Your&nbsp;Story</span>} rightText="ArtBlocks" isLeftLink={true} isRightLink={true} leftLink="/yourstory" rightLink="https://www.artblocks.io/collections/presents/projects/0x99a9b7c1116f9ceeb1652de04d5969cce509b069/388?section=all%20items&category=collections&vertical=presents&contract_id=0x99a9b7c1116f9ceeb1652de04d5969cce509b069&project_id=388&orderByKey=list_eth_price&orderDirection=asc_nulls_last"/>
                            <TwoElementsRow leftText="Disegni di poesia" rightText={<span>Own&nbsp;Contract</span>} isLeftLink={true} isRightLink={true} leftLink="/disegni" rightLink="/corpus/45"/>
                            <TwoElementsRow leftText="People" rightText={<span>Own&nbsp;Contract</span>} isLeftLink={true} isRightLink={true} leftLink="/people" rightLink="/corpus/15"/>
                            <TwoElementsRow leftText="Ouroboros" rightText={<span>Own&nbsp;Contract</span>} isLeftLink={true} isRightLink={true} leftLink="/ouroboros" rightLink="/corpus/0"/>
                            <TwoElementsRow leftText="E28" rightText={<span>Own&nbsp;Contract</span>} isLeftLink={true} isRightLink={true} leftLink="/e28" rightLink="https://opensea.io/collection/encapsuled28"/>
                            <TwoElementsRow leftText="Journey" rightText="KnownOrigin" isLeftLink={true} isRightLink={true} leftLink="/journey" rightLink="https://knownorigin.io/collections/encapsuled/journey"/>
                            <TwoElementsRow leftText={<span>On&nbsp;Writing</span>} rightText="objkt" isLeftLink={true} isRightLink={true} leftLink="/onwriting" rightLink="https://objkt.com/collection/KT1PLR5h8qpANWkT4oJYkiTnTzy3eJx4omms"/>
                            <TwoElementsRow leftText="Capsules" rightText="objkt" isLeftLink={true} isRightLink={true} leftLink="/capsules" rightLink="https://objkt.com/profile/tz1Tguuqstpgd77A7uvnKEgoMcAfcEzTQaSm/created"/>
                            <TwoElementsRow leftText="Raw" rightText="objkt" isLeftLink={true} isRightLink={true} leftLink="/raw" rightLink="https://objkt.com/profile/tz1bd9TdZGm3JxpGfmrTm9NrQt47UK1zi5a6/created"/>
                            <TwoElementsRow leftText="Roads to nowhere" rightText="objkt" isLeftLink={true} isRightLink={true} leftLink="/roads" rightLink="https://objkt.com/profile/encapsuled/created?faContracts=KT1LjmAdYQCLBjwv4S2oFkEzyHVkomAf5MrW"/>
                            <TwoElementsRow leftText="engoesback" rightText="fxhash" isLeftLink={true} isRightLink={true} leftLink="/engoesback" rightLink="https://www.fxhash.xyz/generative/14813"/>
                            <TwoElementsRow leftText="Divergence" rightText="Foundation" isLeftLink={true} isRightLink={true} leftLink="/divergence" rightLink="https://teia.art/objkt/717662"/>
                            <TwoElementsRow leftText="encapsuled x coppola" rightText="teia" isLeftLink={false} isRightLink={true} leftLink="https://foundation.app/collection/div" rightLink=""/>
                        </Grid>
                    </Grid>
                    <Grid item className='desktopHalfMobileFullWidth verticalLine' padding={3} paddingTop={3}>
                        <Grid container spacing={3} direction="column" justifyContent="center" alignItems="center">
                            <TwoElementsRow leftText={<i>recently exhibited at</i>} rightText='' isLeftLink={false} leftLink="" rightLink="" hideHr={true}/>
                            <div>&nbsp;</div>
                            <TwoElementsRow leftText="NonFungible Conference 2024" rightText="Portugal" isLeftLink={false} leftLink="" rightLink=""/>
                            <TwoElementsRow leftText="NFTCC 2024" rightText="Italy" isLeftLink={false} leftLink="" rightLink=""/>
                            <TwoElementsRow leftText="NonFungible Conference 2023" rightText="Portugal" isLeftLink={false} leftLink="" rightLink=""/>
                            <TwoElementsRow leftText="Portale Ostiense @ NFT Rome 2023" rightText="Italy" isLeftLink={false} leftLink="" rightLink=""/>
                            <TwoElementsRow leftText="Indigo @ NFT Factory Paris 2023" rightText="France" isLeftLink={false} leftLink="" rightLink=""/>
                            <TwoElementsRow leftText="ProofOfPeople x Refraction @ NFTNYC 2023" rightText="USA" isLeftLink={false} leftLink="" rightLink=""/>
                            <TwoElementsRow leftText="Criticize By Creating @ NFT Paris 2023" rightText="France" isLeftLink={false} leftLink="" rightLink=""/>
                            <div>&nbsp;</div>
                            <div>&nbsp;</div>
                            <div>&nbsp;</div>
                            <TwoElementsRow leftText={<i>recent interviews & features</i>} rightText='' isLeftLink={false} leftLink="" rightLink="" hideHr={true}/>
                            <div>&nbsp;</div>
                            <TwoElementsRow leftText="var(semic) with theVERSEverse" rightText="2024" isLeftLink={true} leftLink="/varsemic" rightLink=""/>
                            <TwoElementsRow leftText="words beyond words curation on MintGoldDust" rightText="2024" isLeftLink={true} leftLink="https://www.mintgolddust.com/curations/29/" rightLink=""/>
                            <TwoElementsRow leftText="LAL Art collection feature" rightText="2023" isLeftLink={true} leftLink="https://lalart.art/artworks/chronicles-by-encapsuled" rightLink=""/>
                            <TwoElementsRow leftText="RCS on Vertical Crypto Art Residency" rightText="2023" isLeftLink={true} leftLink="https://www.rightclicksave.com/article/how-to-really-make-it-in-web3" rightLink=""/>
                            <TwoElementsRow leftText="ArtBlocks Minters & Makers" rightText="2023" isLeftLink={true} leftLink="https://open.spotify.com/episode/4LWU9SU4LKF8tvnIE0QnZF?si=5f11c46269004d49&nd=1" rightLink=""/>
                            <TwoElementsRow leftText="The Tickle extended interview" rightText="2023" isLeftLink={true} leftLink="https://objkt.com/asset/KT1KhepNMQ38DQ84QzT2ijVtsP1cMrvAyzJj/0" rightLink=""/>
                            <TwoElementsRow leftText="CryptoWriters Talk: Remixing vs Reminting" rightText="2022" isLeftLink={true} leftLink="https://podcasters.spotify.com/pod/show/crypto-writer-talks/episodes/Reminting-vs--Remixing-with-Encapsuled--Sasha-Stiles-and-wordvoid-e1h1d8e" rightLink=""/>
                            <TwoElementsRow leftText="CryptoWriters Talk: How to get writing done" rightText="2022" isLeftLink={true} leftLink="https://podcasters.spotify.com/pod/show/crypto-writer-talks/episodes/How-to-get-writing-done-e1g8fto" rightLink=""/>
                            <TwoElementsRow leftText="Redlions Studios: txt.art" rightText="2022" isLeftLink={true} leftLink="https://www.redlion.studio/txt-art" rightLink=""/>
                            <TwoElementsRow leftText="In Tezos Foundation permanent collection" rightText="2022" isLeftLink={true} leftLink="https://objkt.com/asset/KT1PLR5h8qpANWkT4oJYkiTnTzy3eJx4omms/8" rightLink=""/>
                            <TwoElementsRow leftText="objkt.com first poetry curation" rightText="2022" isLeftLink={true} leftLink="https://objkt.com/explore/previously-featured/poetry/1" rightLink=""/>
                            <TwoElementsRow leftText="The Tickle Lit issue 3" rightText="2022" isLeftLink={true} leftLink="https://objkt.com/asset/KT1LA5NeudWHSKXToy5WZkSmMPE3TT1iey3d/2" rightLink=""/>
                            <TwoElementsRow leftText="The Tickle Lit issue 2" rightText="2022" isLeftLink={true} leftLink="https://objkt.com/asset/KT1LA5NeudWHSKXToy5WZkSmMPE3TT1iey3d/1" rightLink=""/>
                            <TwoElementsRow leftText="The Tickle Lit issue 1" rightText="2022" isLeftLink={true} leftLink="https://objkt.com/asset/KT1LA5NeudWHSKXToy5WZkSmMPE3TT1iey3d/0" rightLink=""/>
                            <TwoElementsRow leftText="The Tickle issue 38" rightText="2022" isLeftLink={true} leftLink="https://objkt.com/asset/hicetnunc/705054" rightLink=""/>
                            <TwoElementsRow leftText="Vertical Crypto Art residency program" rightText="2021" isLeftLink={true} leftLink="https://residency.verticalcrypto.art/artists" rightLink=""/>
                            <TwoElementsRow leftText="MoCDA DigitalTrends words and poems" rightText="2021" isLeftLink={true} leftLink="https://twitter.com/MOCDA_/status/1453343638017265666" rightLink=""/>
                            <TwoElementsRow leftText="CryptoWriters interview" rightText="2021" isLeftLink={true} leftLink="https://podcasters.spotify.com/pod/show/crypto-writer-talks/episodes/Interview-with-Encapsuled-e181ac8" rightLink=""/>
                            <TwoElementsRow leftText="Interview on jlmaxcy's blog" rightText="2021" isLeftLink={true} leftLink="https://jlmaxcy.medium.com/a-conversation-with-encapsuled-4fe7dc06e41a" rightLink=""/>
                        </Grid>
                    </Grid>
                </Grid>

                {/* biography */}
                <Grid container direction="column" justifyContent="center" alignItems="center" paddingTop={"50px"} paddingBottom={"50px"}> 
                    <Grid item>
                        <span id="PART_ONE" style={{fontSize: '38px', fontWeight: "bolder"}}>PART ONE</span>
                    </Grid>
                    <Grid item>
                        <span style={{fontSize: '20px'}}>biography</span>
                    </Grid>
                    <Grid item>
                        <p className="bioP">
                            Michelangelo (encapsuled) is an Italian (a)semantic language artist. 
                            He uses both conventional and asemic language in his work. He believes in art as communication, 
                            considering the audience and the context as part of the experience. His core themes are meaning and simplicity. 
                            Currently he's working on a few projects that combine physical and digital form.<br/><br/>
                            He started publishing works in 2021 with several writing projects and has since been featured in publications 
                            such as the tickle, txt.art by Redlion, Breezy, and Artblocks Minters & Makers.<br/><br/>
                            His most recent focus is on asemic writing. He released 'Your Story' on Artblocks, a collection where all tokens (or rather pages) 
                            combined form a book, available also printed to the collectors. The interpretation of this book is up to the imagination of the viewer, 
                            always telling a different story.<br/><br/>
                            He created 'Manifesto', in collaboration with Breezy's initiative 'Criticize By Creating'. 
                            Manifesto is a piece that comments on the loss of critical thinking and individuality of the contemporary society. 
                            When scanned in AR through Artivive it reveals the six dystopic statements that compose it.<br/><br/>
                            Other recent releases are 'Chronicles' with VerticalCryptoArt during the ProofOfPeople festival in NYC, 'Indaco' for the Indigo show at NFT Factory Paris, 
                            'Loss' that has been part of the Digital Art Spring auction by Pandolfini, and 'Endless Dialogue' that was 
                            exhibited in Rome and Lisbon as part of NFTCC.<br/><br/>
                            In the crypto-writers space, encapsuled created 'Dialogue Capsules', where he gathered a word from each collector 
                            as a prompt for the next piece of dialogue. Together the pieces formed one unique conversation between two characters. 
                            Other projects include 'Disegni di Poesia' and '28', poetry with the text stored directly on the blockchain 
                            leveraging his own custom smart contracts.<br/><br/>
                        </p>
                    </Grid>
                </Grid>

                {/* works */}
                <Grid container direction="column" justifyContent="center" alignItems="center" paddingBottom={"50px"}> 
                    <Grid item>
                        <span id="PART_TWO" style={{fontSize: '38px', fontWeight: "bolder"}}>PART TWO</span>
                    </Grid>
                    <Grid item>
                        <span style={{fontSize: '20px'}}>selected works</span>
                    </Grid>
                </Grid>
                <Link to="/varsemic">
                    <img
                        style={{background: '#fafafa'}}
                        src='/images/varsemic/varsemic_1.svg'
                        alt='varsemic' className="pieceImgHome"
                    />
                </Link>
                <Link to="/carattere">
                    <img
                        style={{background: '#fafafa'}}
                        src='/images/carattere_0.svg'
                        alt='carattere' className="pieceImgHome"
                    />
                </Link>
                <Link to="/taccuini">
                    <img
                        src='/images/taccuini/firma1.jpg'
                        alt='taccuini' className="pieceImgHome"
                    />
                </Link>
                <Link to="/evolutionv0">
                    <img
                        src='/images/evolution_final.svg'
                        alt='evolution' className="pieceImgHome"
                    />
                </Link>
                <Link to="/endlessdialogue">
                    <Grid container direction="column" justifyContent="center" alignItems="center">
                        <ReactP5Wrapper sketch={sketch}/>
                    </Grid>
                </Link>
                <Link to="/loss">
                    <img
                        src='/images/loss/3.png'
                        alt="Loss" className="pieceImgHome"
                    />
                </Link>
                <Link to="/loss">
                    <img
                        src='/images/linebreak/linebreak3.jpg'
                        alt="Loss" className="pieceImgHome"
                    />
                </Link>
                <Link to="/Indaco">
                    <img
                        src='/images/indaco_sm.png'
                        alt="Indaco" className="pieceImgHome"
                    />
                </Link>
                <Link to="/chronicles">
                    <img
                        src='/images/chronicles.png'
                        alt="Chronicles" className="pieceImgHome"
                    />
                </Link>
                <Link to="/manifesto">
                    <img
                        src='/images/manifesto/manifestov2xs.png'
                        alt="Manifesto" className="pieceImgHome"
                    />
                </Link>
                <Link to="/yourstory">
                    <img
                        src='/images/yourstory/388000097.png'
                        alt="YourStory" className="pieceImgHome"
                    />
                </Link>
                <Link to="/disegni">
                    <img
                        src='/images/45.png'
                        alt="Trees" className="pieceImgHome"
                    />
                </Link>
                <Link to="/e28">
                    <img
                        src='/images/e28/16.png'
                        alt="Demise" className="pieceImgHome"
                    />
                </Link>
                <Link to="/journey">
                    <img
                        src="https://content.knownorigin.io/network/mainnet/edition/2873000/asset.png"
                        alt="Journey" className="pieceImgHome"
                    />
                </Link>
                <Link to="/capsules">
                    <img
                        src='/images/dialogues/artifact (28).png'
                        alt="Capsules" className="pieceImgHome"
                    />
                </Link>
                <Link to="/people">
                    <img
                        src='/images/15.png'
                        alt="Actor" className="pieceImgHome"
                    />
                </Link>
                <Link to="/raw">
                    <img
                        src='/images/raw/raw2.png'
                        alt="The atom" className="pieceImgHome"
                    />
                </Link>
                <Link to="/onwriting">
                    <img
                        src='/images/onwriting/inkPaper.gif'
                        alt="inkandpaper" className="pieceImgHome"
                    />
                </Link>
                <Link to="/ouroboros">
                    <img
                        src='/images/0.png'
                        alt="Shred" className="pieceImgHome"
                    />
                </Link>
                <Link to="/roads">
                    <img
                        src="/images/roads/day41.gif"
                        alt="Roads" className="pieceImgHome"
                    />
                </Link>
                <Link to="/engoesback">
                    <img
                        src='/images/engoesback/preview.png'
                        alt="engoesback" className="pieceImgHome"
                    />
                </Link>
                <Link to="/divergence">
                    <img
                        src="https://f8n-production-collection-assets.imgix.net/0x1407528B0DA3641CeC37c8F2B273dA424D0107C2/1/QmcPq45UBwdXDf7V7qFqqxhMjHNaLUYWKj3pGKxpSCiFPN/nft.png"
                        alt="Divergence" className="pieceImgHome"
                    />
                </Link>
                <a href="https://teia.art/objkt/717662" target="_blank" rel="noopener noreferrer" className="black">
                    <img
                        src='/images/encapsuledxcoppola.png'
                        alt="encapsuledxcoppola" className="pieceImgHome"
                    />                            
                </a>
            </div>
        )
    }
}

export default Home;