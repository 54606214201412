import React, { Component } from 'react'
import LeftNav from "../components/LeftNav"

export default class Evolution extends Component {
    render() {
        return(
            <div className="pageContainer">
                <LeftNav/>
                <div className="mainContent blackBg" style={{overflowY: "auto"}}>
                    <div>
                        <div style={{padding: "50px 50px 0 50px"}} className="textJustified">
                            <p className="collectionTitle">
                                var(semic)
                            </p>
                            <a href="https://objkt.com/@encapsuled?fa_contract=KT18y3uZCpeE7SamUgjGWT9VYPWx4wQkXVqY" target="_blank" rel="noopener noreferrer">
                                Available on objkt
                            </a>
                            <br/><br/>
                            <p>
                                encapsuled: var(semic) is a generative conversation exploring the limitations and potential of human ideas read by machines.<br/>
                                The project marks the December 2024 edition of var(verse), an evolving collection of curated poetry made by poets writing & creating today. curated by Elisabeth Sweet (ES) on behalf of theVERSEverse<br/><br/>
                                The dialogue is in three acts. Each act starts with three questions asked by Elisabeth. My three answers are then the ispiration for a fourth question, which is answered by an asemic algorithm I created.
                            </p>
                            <p>
                                Why asemic writing as the last response of each act? <i>Whereof one cannot speak, thereof one must be silent.</i> - Wittgenstein<br/>
                            </p>
                            <p style={{fontSize: 18, paddingTop: 12}}><b>Part 1: PARSING PATTERNS</b></p>
                            <p>
                                <i>ES: In An Enquiry Concerning Human Understanding (1748), David Hume writes, “Every idea is copied from some preceding impression of sentiment; and where we cannot find any impression, we may be certain that there is no idea.” How do you think about randomness in this context?</i><br/><br/>
                                M: I'm aligned with Hume. All we know is due to our previous impressions, what we call perceptions in the modern context. Without perception there is nothing, and yet from connecting perceptions we can form new ideas.<br/>
                                So, nothing is ever really new or random. Every individual will necessarily evolve and move through space and time as a result of all previous experiences. It's much like seeded randomness in generative art: not true randomness but only apparent. In generative art what usually happens is that the artist crafts an algorithm with some parameters that have a degree of freedom, which is a complex way of saying "a set of choices". To go from the algorithm to a specific output (often called "iteration"), choices need to be made. That's normally the job of the seed. Given a specific seed the algorithm produces all the choices, necessarily. No matter how many times it's executed, with the same seed you get the same result. It looks like randomness to an external observer, but the algorithm already had all the choices inside of it. Reality is much the same: there are rules that control everything even if those rules are not evident - secret rules like secret powers - not secret in absolute, they are possible to know in theory, we're just not there yet.
                            </p>
                            <p>
                                <i>ES: Thinking about Wittgenstein through the lens of Hume, Wittgenstein digs into the “secret powers” of words as they appeal to our “sensible qualities” which allow us to communicate with each other through language. How do words create impressions of the world and invoke experience beyond the moment of dictation or conversation in which they arose? What might Wittgenstein say about impressions or experiences formed beyond words?</i><br/><br/>
                                M: I'd love to ask Wittgenstein and Hume this directly, but without knowing their perspective I'll talk from mine instead. I'd say that the secret powers of words are storage, abstraction, and condensation. Language is able to draw from the existing impressions of the world that form from our experience and extend to new ideas that then become part of our domain of knowledge.<br/>
                                A basic example: think of a blue walnut. Assuming you’ve never seen a blue walnut before, you can still picture it in your mind by applying the property "blue" to the object "walnut." Language extended your knowledge without you having to experience the object directly. This is a very basic example, but it extends all the way to "best practices in project management" and other abstract concepts. Properties of existing experiences can be applied to new ones, so that with only a few words you can expand knowledge by a lot.<br/>
                                So, to define the secret powers of words:<br/>
                                Storage: ability to "store" an experience to be communicated with other people<br/>
                                Abstraction: ability to extend an existing experience or part of it to a new experience<br/>
                                Condensation: ability to craft new words to summarize complex concepts
                            </p>
                            <p>
                                <i>ES: Hume’s bold argument about the doctrine of necessity analyzes our bias toward believing in cause and effect, neither of which we can really know. His rebuttal of causality makes us question the Western conception of reality. How would you think about the human in this context?</i><br/><br/>
                                M: I agree with Hume: the concept of cause and effect is flawed, there's only proximity and repetition. So the only difference between a cause and an effect is that one occurs before the other, but it's also impossible to think of a cause with no effects or effects without a cause, since the terms are defined interdependently. Causality is something we define to help us understand, organize, and predict the world, but I don't think it's something that belongs to the world itself.<br/>
                                To me the human is an inevitable and continuous reality. An effect of all precedent moments and cause of all subsequent. The only difference is the moment we are considering.  
                            </p>
                            <p>
                                <i>ES: Considering the continuity of reality amidst the semblance of change described in different yet intersecting ways by Hume and Wittgenstein, it might be said that nothing is ever really new, nothing ever really changes, and everything is known but not by a single mind. However, we can point to examples from cognitive memory and the present that would qualify as new, suggesting that there is an experience or reality of newness. Given this paradox, what could we call something that is not new but previously unknown?</i><br/><br/>
                                M: 
                            </p>
                            <a href="https://objkt.com/tokens/KT18y3uZCpeE7SamUgjGWT9VYPWx4wQkXVqY/7" target="_blank" rel="noopener noreferrer"><img
                                src='/images/varsemic/varsemic_1.svg'
                                alt='evolution' className="pieceImgWide"
                            /></a>
                            <br/><br/>
                            <p style={{fontSize: 18, paddingTop: 12}}><b>Part 2: ONLY MIRACULOUS</b></p>
                            <p>
                                <i>ES: If proximity and repetition guide our perception, it might be said that something familiar is an impression held close and repeated often. Thus something unfamiliar would be far away and infrequently experienced, if ever. We can explain the familiar while the unfamiliar is unexplainable. That which is unexplainable is typically dismissed, feared, or revered. Why does the unexplained – the unfamiliar, the far and infrequent – intrigue a human mind so set on pattern-seeking?</i><br/><br/>
                                M: Exactly because it breaks the pattern. When the unexpected happens, it means that our system failed to predict the course of events, it made an error. Errors are fatal in evolution: what cannot adapt perishes. So it's critical for us to take into account this new piece of information - the unexplained - and explain it, incorporating it in our realm of possibilities so that we are not taken again by surprise. Fear and reverence are opposite ends emotionally but they share the same high intensity, reflecting the importance of this new information.<br/>
                                However, incorporating new information means that we need to restructure our system and that is very hard to do, often even traumatic. So rather than going through this process, an easier answer is denial, dismissing the possibility of our system being wrong. This can be useful to preserve some coherence in times when our system is already challenged but usually leads to less adaptation - and adaptation is necessary for existence.
                            </p>
                            <p>
                                <i>ES: The seduction of a miracle is that it can never be proven, yet this uncertainty is what gives it the strength to withstand reason. Miracles rely on human testimony, which Hume says is something we can never fully believe because “it is nothing strange that men should lie in all ages,” yet it is the only thing we can hold when considering history. Miracles are used to codify religion and create an aura of sanctity around people and objects, yet their persistence throughout history points to a real impression – therefore a perceivable truth – experienced by one or many. When can we know if something is “only marvellous, [or] really miraculous”?</i><br/><br/>
                                M: Miracles are a specific category of facts. And already facts are quite complex phenomenons: they cannot determine themselves, facts require explanation, interpretation. There is always a context associated with a fact and the context is what determines the meaning of the fact and its truth or falsity. For example, what does "coming back to life" mean?<br/>
                                To answer, even literally and not metaphorically, we need to define what "life" is. Every time a human interacts with another, they share parts of themselves that become part of each other. These parts remain even after the physical death and inform our decisions, our actions, our future. "Coming back to life" can mean that the influence a person has on us becomes stronger.<br/>
                                Language requires definition, definitions are made by humans, humans necessarily have a specific context. “Only marvellous” or “really miraculous” are subjective lenses applied to an objective fact, remembering that a fact can never be observed without a lens.
                            </p>
                            <p>
                                <i>ES: Wittgenstein writes much about the “ostensive definition” of words, or what a word points to in reality. One might think of this as the role of the word. What is the role of “miracle” in language and subsequently in society?</i><br/><br/>
                                M: Ostensive definitions work when the parties involved understand the category that is being talked about - referring back to the blue walnut example, if I point to it and say "blue" to someone who does not know English, they might think that "blue" refers to the type of nut and not to the color. It's important that the people in dialogue are in agreement on the category that is being talked about, and I think that a misalignment in categories is often the problem. A group of people may think "miracle" as "act of God(s)" while others may think about it as "something unexplainable". But these two categories are radically different, for one implies the existence of at least one superior being and the other not. I think that "miracle" is a polarizing word and as such should be used carefully.  
                            </p>
                            <p>
                                <i>ES: Putting together Wittgenstein’s concept and Hume’s argument, a miracle is “a proposition” in “violation of the laws of human nature.” To believe a miracle, one must suspend their continuous impression of reality and allow an anomalous category of facts to become the pattern. If a person did not witness the miracle directly, then they place belief in the testimony of others, which amounts to agreement with the language used to express an impression or experience. Hume says testimony is a challenge because men lie while Wittgenstein might say that even the testimony, because of its root in language, hides the reality or essence of the event. Yet, despite argument and disagreement, miracles and their claim persist throughout history, demonstrating the strength of their proposition and thus the power of their original and continuous impression.<br/>
                                What might we call a miracle everyone agrees on?</i><br/><br/>
                                M: 
                            </p>
                            <a href="https://objkt.com/tokens/KT18y3uZCpeE7SamUgjGWT9VYPWx4wQkXVqY/8" target="_blank" rel="noopener noreferrer"><img
                                src='/images/varsemic/varsemic_2.svg'
                                alt='evolution' className="pieceImgWide"
                            /></a>
                            <br/><br/>
                            <p style={{fontSize: 18, paddingTop: 12}}><b>Part 3: INVISIBLE VALENCE</b></p>
                            <p>
                                <i>ES: We’ve explored the illusion of randomness, the miscalculation of newness, and the incoherence of miracles. Still, these three propositions, randomness, newness, and miracles maintain a firm grip on the human mind. The fear or yearning for some random event to change one’s life, the revelatory impact of experiencing something new, and faith in the occurrence and possibility of miracles serve as conscious guides to life, singularly or in combination.<br/>
                                Randomness, newness, and miracles all carry a tremendous amount of uncertainty, but not necessarily doubt. Hume argues vividly against the tyrannical doubt which Skeptics hold dear, and Wittgenstein sums up the cruelty of doubt (through the English of G.E.M. Anscombe): “Just try—in a real case—to doubt someone else’s fear or pain.”<br/>
                                What makes uncertainty a better philosophical guide than doubt?</i><br/><br/>
                                M: The two phenomena are quite similar, as they both refer to a lack of knowledge about something, some missing associations between perceptions we had or between a perception and its context. With both, our mind does not have a path and it's working hard to create one.<br/>
                                The main difference between doubt and uncertainty is our inclination to form the path. In the case of uncertainty, we want to create it, while in the case of doubt, we are more inclined to reject it. So uncertainty is the result of an open attitude while doubt is the reflection of our inclination to stick to our current paths.<br/>
                                In this light, uncertainty is decidedly a better philosophical guide as it's more adaptive and makes us incorporate new inputs, resulting in more complete and malleable theories and less dogmatic beliefs.
                            </p>
                            <p>
                                <i>ES: Hume writes in “Of Standards of Taste” (1757), “The object of eloquence is to persuade, of history to instruct, of poetry to please by means of the passions and the imagination.” Eloquence, history, and poetry are transmitted through embodied language, spoken and written.<br/>
                                In Philosophical Investigations (1953) Wittgenstein (through the English of Anscombe) writes: “It would never have occurred to us to think that we felt the influence of the letters on us when reading, if we had not compared the case of letters with that of arbitrary marks. And here we are indeed noticing a difference. And we interpret it as the difference between being influenced and not being influenced.”<br/>
                                Asemic writing may be defined as a mix of symbols and lines written without meaning, or even perhaps as “arbitrary marks,” as Wittgenstein describes above. As your practice is heavily rooted in the asemic, and considering the statement and sentiment of Wittgenstein alongwith the significance inculcated by Hume, what is the influence of “arbitrary marks” where one might expect a codified language?</i><br/><br/>
                                M: It's all about the significance of the marks. A symbol is something that stands for something else, and one needs a way to decode the symbol to understand what it stands for. In the case of arbitrary marks there is no decoding possible, and so the marks are not symbols and as Wittgenstein points out they cannot influence us.<br/>
                                When asemic writing is used how I use it, the decoding is not explicit, but possible. There is an intent in my creative process, an idea behind the order that can be found. This makes my asemic marks symbols. In action painting, for example a painting by Pollock, there is no idea or order, hence nothing to decode. It simply is and it doesn't stand for something else, so it cannot be a symbol.<br/>
                                Asemic writing invites the viewer to decode in private. The endeavor activates their imagination, which is often sleeping, and they complete the process by returning to the original message, finishing the artwork themselves.
                            </p>
                            <p>
                                <i>ES: “Beauty, whether moral or natural, is felt, more properly than perceived,” writes Hume in the final section of An Enquiry Concerning Human Understanding (1748). Nine years later, Hume writes, “Beauty is no quality in things themselves.” Beauty felt may be followed with the hope of making its impression indelible on the mind, body, or soul. Whether we develop an affinity or a fear, as Rainer Maria Rilke writes of the terror born in beauty, it may be said that we are guided by what creates a sense of beauty within us.<br/>
                                I cannot help but connect beauty in this context to intuition: the feeling of knowing what is right or wrong without evidence stronger than an internal sense. Intuition is our greatest guide through uncertainty. Of intuition, Wittgenstein (through Anscombe) writes, “If intuition is an inner voice—how do I know how I am to obey it? And how do I know that it doesn’t mislead me? For if it can guide me right, it can also guide me wrong.” Wittgenstein highlights how challenging it can be to hear and follow one’s intuition, if in fact it is a source of deep knowing.<br/>
                                If beauty only exists in the mind of the perceiver, might uncertainty bear the same weight? Is uncertainty, like beauty, more properly felt than perceived, thus exerting its influence more on our senses rather than on reality?</i><br/><br/>
                                M: Ostensive definitions work when the parties involved understand the category that is being talked about - referring back to the blue walnut example, if I point to it and say "blue" to someone who does not know English, they might think that "blue" refers to the type of nut and not to the color. It's important that the people in dialogue are in agreement on the category that is being talked about, and I think that a misalignment in categories is often the problem. A group of people may think "miracle" as "act of God(s)" while others may think about it as "something unexplainable". But these two categories are radically different, for one implies the existence of at least one superior being and the other not. I think that "miracle" is a polarizing word and as such should be used carefully, thinking about the tension mentioned earlier.  
                            </p>
                            <p>
                                <i>ES: What is the sound when a mind transitions from uncertainty to certainty?</i><br/><br/>
                                M: 
                            </p>
                            <a href="https://objkt.com/tokens/KT18y3uZCpeE7SamUgjGWT9VYPWx4wQkXVqY/9" target="_blank" rel="noopener noreferrer"><img
                                src='/images/varsemic/varsemic_3.svg'
                                alt='evolution' className="pieceImgWide"
                            /></a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}