import React, { Component } from 'react'
import LeftNav from "../components/LeftNav"

export default class Evolution extends Component {
    render() {
        return(
            <div className="pageContainer">
                <LeftNav/>
                <div className="mainContent blackBg" style={{overflowY: "auto"}}>
                    <div>
                        <div style={{padding: "50px 50px 0 50px"}} className="textJustified">
                            <p className="collectionTitle">
                                var(semic)
                            </p>
                            <a href="https://objkt.com/@encapsuled?fa_contract=KT18y3uZCpeE7SamUgjGWT9VYPWx4wQkXVqY" target="_blank" rel="noopener noreferrer">
                                Available on objkt
                            </a>
                            <br/><br/>
                            <p>
                                encapsuled: var(semic) è una conversazione generativa che esplora le limitazioni e il potenziale delle idee umane lette da macchine.<br/>
                                Il progetto è l'edizione di Dicembre 2024 di var(verse), una collezione in evoluzione di poesia selezionata creata da poeti attivi oggi. Curata da Elisabeth Sweet per conto di theVERSEverse.<br/><br/>
                                Il dialogo è in tre atti. Ogni atto inizia con tre domande poste da Elisabeth. Le mie tre risposte sono poi l'ispirazione per una quarta domanda, che è infine risposta da un algoritmo asemico creato da me.
                            </p>
                            <p>
                                Perché il linguaggio asemico come ultima risposta in ogni atto? <i>Su ciò di cui non si può parlare, si deve tacere.</i> - Wittgenstein<br/>
                            </p>
                            <p style={{fontSize: 18, paddingTop: 12}}><b>Parte 1: ANALIZZANDO SCHEMI</b></p>
                            <p>
                                <i>ES: In "Ricerca sull'intelletto umano" (1748), David Hume scrive: "ogni idea è copiata da una qualche precedente impressione o sentimento; e dove non possiamo trovare nessuna impressione, possiamo essere sicuri che non ci siano idee." Come pensi alla casualità in questo contesto?</i><br/><br/>
                                M: Sono allineato con Hume. Tutto quello che conosciamo è dovuto alle nostre precedenti impressioni, quelle che chiamiamo percezioni nel contesto moderno. Senza percezione non c'è nulla, eppure dalla connessione di percezioni possiamo formare nuove idee.<br/>
                                Quindi, niente è mai davvero nuovo o casuale. Ogni individuo necessariamente evolve e si muove nello spazio e nel tempo come risultato di tutte le sue esperienze precedenti. È come la casualità derivata da un seme nell'arte generativa: non è vera casualità ma solo apparente. In arte generativa quello che succede solitamente è che l'artista crea un algoritmo con alcuni parametri che hanno una certa libertà, che è un modo complicato di dire "un insieme di scelte". Per andare dall'algoritmo a un pezzo specifico (spesso chiamato "iterazione"), le scelte devono essere fatte. Questo è il ruolo del seme. Dato uno specifico seme l'algoritmo produce tutte le scelte, necessariamente. Non importa quante volte è eseguito, con lo stesso seme si arriva allo stesso risultato. Sembra casuale a un osservatore esterno, ma l'algoritmo aveva già tutte le scelte al suo interno. La realtà è lo stesso: ci sono regole che controllano tutto anche se queste regole non sono evidenti, non segrete in assoluto, sono possibili da conoscere in teoria, ma non ci siamo ancora arrivati.
                            </p>
                            <p>
                                <i>ES: Pensando a Wittgenstein attraverso la lente di Hume, Wittgenstein parla dei "poteri segreti" delle parole quando si agganciano alle nostre "qualità sensitive" che ci permettono di comunicare con gli altri attraverso il linguaggio. Come fanno le parole a creare impressioni del mondo e invocare esperienze al di là del momento in cui sono pronunciate? Cosa potrebbe dire Wittgenstein delle impressioni o esperienze formate al di là delle parole?</i><br/><br/>
                                M: Mi piacerebbe chiederlo direttamente a Wittgenstein e Hume, ma senza sapere la loro prospettiva parlerò invece dalla mia. I "poteri segreti" delle parole sono conservare, astrarre, e condensare. Il linguaggio è in grado di trarre dalle impressioni del mondo esistenti che formano la nostra esperienza e estendersi a nuove idee che poi diventano parte del nostro dominio di conoscenza.<br/>
                                Un esempio: pensa a una noce blu. Assumendo che tu non abbia mai visto una noce blu prima d'ora, puoi comunque immaginarla nella tua mente applicando la proprietà "blu" all'oggetto "noce". Il linguaggio estende la tua conoscenza senza che tu abbia esperienza diretta dell'oggetto. Questo è un esempio molto semplice, ma si estende fino a concetti astratti come "migliori tecniche per la gestione dei progetti". Le proprietà di esperienze esistenti possono essere applicate a nuove esperienze, così che con solo poche parole puoi espandere la conoscenza di molto.<br/>
                                Per ricapitolare i poteri segreti delle parole:<br/>
                                Conservare: abilità di conservare un'esperienza che può essere poi comunicata ad altre persone<br/>
                                Astrazione: abilità di estendere un'esperienza esistente o parte di essa a una nuova esperienza<br/>
                                Condensazione: abilità di create nuove parole per sintetizzare concetti complessi
                            </p>
                            <p>
                                <i>ES: L'argomento di Hume sulla dottrina della necessità analizza la nostra tendenza a credere al principio di causa ed effetto, che non possiamo davvero conoscere. Il suo rifiuto della causalità fa mettere in dubbio il concetto occidentale di realtà. Come pensi all'essere umano in questo contesto?</i><br/><br/>
                                M: Sono d'accordo con Hume: il concetto di causa ed effetto è imperfetto, esistono solo vicinanza e ripetizione. L'unica differenza tra una causa e un effetto è che una precede l'altra, ma è impossibile pensare a cause senza effetti o a effetti senza cause, data la definizione interdipendente dei termini. La causalità è qualcosa che definiamo per aiutarci a capire, organizzare, e predirre il mondo, ma non credo sia qualcosa che appartenga al mondo stesso.<br/>
                                Per me l'essere umano è una realtà continua e inevitabile. Un effetto di tutti i precedenti momenti e causa di tutti quelli successivi. L'unica differenza è il momento che prendiamo in considerazione.
                            </p>
                            <p>
                                <i>ES: Considerando la continuità della realtà tra la parvenza del cambiamento descritta da Hume e Wittgenstein in modi diversi ma che si intrecciano, possiamo dire che nulla è mai davvero nuovo, nulla mai davvero cambia, e tutto è conosciuto ma non da una mente singola. Tuttavia, possiamo fare esempi dalla memoria cognitiva e dal presente che si qualificherebbero come nuovi, suggerendo che c'è un'esperienza di realtà nella novità. Dato questo paradosso, come potremmo chiamare qualcosa che non è nuovo ma precedentemente sconosciuto?</i><br/><br/>
                                M: 
                            </p>
                            <img
                                src='/images/varsemic/varsemic_1.svg'
                                alt='evolution' className="pieceImgWide"
                            />
                            <br/><br/>
                            <p style={{fontSize: 18, paddingTop: 12}}><b>Parte 2: SOLO MIRACOLOSO</b></p>
                            <p>
                                <i>ES: Se vicinanza e ripetizione guidano la nostra percezione, si potrebbe dire che qualcosa di familiare è un'impressione a noi vicina e ripetuta frequentemente. Quindi qualcosa di non familiare sarebbe distante e ripetuto non frequentemente, se mai. Possiamo spiegare il familiare mentre il non familiare è inspiegabile. Quello che è inspiegabile è normalmente ignorato, temuto o riverito. Perché il l'inspiegabile - il non familiare, il lontano e non frequente - intrigano la mente umana così abituata a cercare schemi?</i><br/><br/>
                                M: Esattamente perché rompe lo schema. Quando succede l'inaspettato, significa che il nostro sistema ha fallito nel predirre il corso degli eventi, ha fatto un errore. Gli errori sono fatali nell'evoluzione: ciò che non si adatta perisce. Per questo è critico per noi prendere in considerazione questo nuovo pezzo di informazione - l'inspiegabile - e spiegarlo, incorprandolo nel nostro regno delle possibilità così che non siamo più colti di sorpresa. Paura e reverenza sono estremi opposti dal punto di vista emozionale ma condividono la stessa intensità, riflettendo l'importanza di questa nuova informazione.<br/>
                                In ogni caso, incorporare la nuova informazione significa che dobbiamo ristrutturare il nostro sistema e questo è molto difficile, spesso persino traumatico. Per cui piuttosto che attraversare questo processo una risposta più semplice è il negare, rifiutare la possibilità che il nostro sistema sia in errore. Questo può essere utile per preservare un po' di coerenza quando il nostro sistema è già in difficoltà ma di solito questo porta a meno adattamento - e l'adattamento è necessario per l'esistenza.
                            </p>
                            <p>
                                <i>ES: La seduzione di un miracolo è che non può mai essere provato, ma questa incertezza è quella che gli dà la forza di resistere alla ragione. I miracoli fanno affidamento sulla testimonianza umana, che Hume dice essere qualcosa a cui non possiamo mai credere pienamente perché "non è nulla di strano che gli uomini mentano in tutte le epoche", eppure è l'unica cosa a cui ci possiamo affidare quando consideriamo la storia. I miracoli sono usati per codificare la religione e creare un'aura di santità attorno a persone e oggetti, eppure la loro persistenza attraverso la storia punta a una reale impressione - quindi a una verità percepibile - esperita da uno o molti. Quando possiamo sapere se qualcosa è "solo meraviglioso, [o] realmente miracoloso"?</i><br/><br/>
                                M: I miracoli sono una specifica categoria di fatti. E già i fatti in generale sono un fenomeno abbastanza complesso: non possono determinarsi da soli, i fatti richiedono spiegazione, interpretazione. C'è sempre un contesto associato con un fatto e il contesto è quello che ne determina il significato e la sua verità o falsità. Per esempio, cosa significa "tornare in vita"?<br/>
                                Per rispondere, anche lettalmente e non metaforicamente, dobbiamo definire cosa significa "vita". Ogni volta che una persona interagisce con un'altra, condividono parti di loro che diventano parti dell'altra. Queste parti rimangono anche dopo la morte fisica e informano le nostre decisioni, le nostre azioni, il nostro futuro. "Tornare in vita" può significare che l'influenza di una persona su di noi si è rafforzata.<br/>
                                Il linguaggio richiede definizioni, le definizioni sono create dagli uomini, gli uomini necessariamente hanno un contesto specifico. "Solo meraviglioso" o "realmente miracoloso" sono lenti soggettive applicate a un fatto oggettivo, ricordandosi che un fatto non può mai essere osservato senza una lente.
                            </p>
                            <p>
                                <i>ES: Wittgenstein scrive molto sulle "definizioni ostensive" delle parole, o quello a cui una parola indica in realtà. Uno potrebbe pensare a questo come il ruolo della parola. Qual è il ruolo di "miracolo" nel linguaggio e di conseguenza nella società?</i><br/><br/>
                                M: Le definizioni ostensive funzionano quando le parti coinvolte comprendono la categoria di cui si sta parlando - riprendendo l'esempio della noce blu, se io la indicassi e dicessi "blu" a qualcuno che non sa l'italiano, questa persona potrebbe pensare che "blu" si riferisca al tipo di noce e non al colore. È importante che le persone in dialogo siano d'accordo sulla categoria di cui si sta parlando, e credo che un disallineamento in categorie sia spesso il problema. Un gruppo di persone potrebbe pensare a "miracolo" come "atto divino" mentre altri potrebbero pensare a "qualcosa di inspiegabile". Ma queste due categorie sono radicalmente diverse, una implica l'esistenza di un'entità superiore e l'altra no. Penso che "miracolo" sia una parola polarizzante e per questo debba essere usata con cautela.
                            </p>
                            <p>
                                <i>ES: Mettendo insieme il concetto di Wittgenstein e l'argomento di Hume, un miracolo è "una proposizione" in "violazione delle leggi della natura umana". Per credere in un miracolo, uno deve sospendere la propria continua impressione della realtà e permettere a una categoria anomala di fatti di diventare uno schema. Se una persona non è stata testimone del miracolo direttamente, allora affida la credenza nella testimonianza di altri, e questa dipende dalla concordanza con il linguaggio usato per esprimere un'impressione o esperienza. Hume dice che la testimonianza è una sfida perché gli uomini mentono mentre Wittgenstein potrebbe dire che anche la testimonianza, data la sua radice nel linguaggio, nasconde la realtà o l'essenza dell'evento. Eppure, nonostante discussioni e disaccordi, i miracoli e la loro rivendicazione persistono attraverso la storia, dimostrando la forza della loro proposizione e quindi il potere della loro impressione originale e continua.<br/>
                                Come potremmo chiamare un miracolo su cui tutti sono d'accordo?</i><br/><br/>
                                M: 
                            </p>
                            <img
                                src='/images/varsemic/varsemic_2.svg'
                                alt='evolution' className="pieceImgWide"
                            />
                            <br/><br/>
                            <p style={{fontSize: 18, paddingTop: 12}}><b>Parte 3: VALENZA INVISIBILE</b></p>
                            <p>
                                <i>ES: Abbiamo esplorato l'illusione della casualità, gli errori di calcolo della novità, e l'incoerenza dei miracoli. Eppure, queste tre proposizioni, casualità, novità, e miracoli mantengono una salda presa sulla mente umana. La paura o il desiderio che qualche evento casuale cambi la propria vita, l'impatto rivelatorio di vivere qualcosa di nuovo, e la fede nell'occorrenza e la possibilità dei miracoli servono da guida cosciente alla vita, singolarmente o in combinazione.<br/>
                                Casualità, novità, e miracoli portano con loro una tremenda quantità di incertezza, ma non necessariamente dubbio. Hume è contrario al dubbio tirannico che è caro agli scettici, e Wittgenstein riassume la crudeltà del dubbio: "Prova un po' a mettere in dubbio - in un caso reale - l'angoscia, il dolore di un'altra persona!"<br/>
                                Cosa rende l'incertezza una guida filosofica migliore del dubbio?</i><br/><br/>
                                M: I due fenomeni sono molto simili, entrambi si riferiscono a una mancanza di conoscenza di qualcosa, delle associazioni mancanti tra percezioni che abbiamo avuto o tra una percezione e il suo contesto. In entrambi i casi, la nostra mente non ha un sentiero e sta lavorando duramente per crearne uno.<br/>
                                La differenza principale tra dubbio e incertezza è la nostra inclinazione a creare il sentiero. Nel caso dell'incertezza vogliamo crearlo, mentre nel caso del dubbio siamo più inclini a rifiutarlo. L'incertezza è il risultato di un'attitudine aperta mentre il dubbio è il riflesso della nostra inclinazione ad attenerci ai nostri sentieri attuali.<br/>
                                Sotto questa luce, l'incertezza è decisamente una guida filosofica migliore vista la sua adattività e l'abilità di incorporare nuovi dati, risultando in teorie più complete e malleabili e meno credenze dogmatiche.
                            </p>
                            <p>
                                <i>ES: Hume scrive ne "La regola del gusto" (1757), "Oggetto dell'eloquenza è di persuadere, della storia è di istruire, della poesia di piacere per mezzo delle passioni e dell'immaginazione." Eloquenza, storia, e poesia sono trasmesse attraverso linguaggio parlato e scritto.<br/>
                                Nelle "Ricerche filosofiche" (1953) Wittgenstein scrive: "Se non avessimo confrontato il caso delle lettere con quello dei segni arbitrari non ci sarebbe mai capitato di pensare che, leggendo, sentiamo l'influsso delle lettere. E qui, veramente, notiamo una differenza. E l'interpretiamo come influsso, e assenza di influsso."<br/>
                                La scrittura asemica può essere definita come un mix di simboli e linee scritte senza significato, o addirittura come "segni arbitrari", come descrive Wittgenstein. Dato che la tua pratica ha radici nell'asemico, e considerando la frase e il sentimento di Wittgenstein assieme alla significanza inculcata da Hume, qual è l'influenza di "segni arbitrari" dove uno si può aspettare un linguaggio codificato?</i><br/><br/>
                                M: Sta tutto nella significatività dei segni. Un simbolo è qualcosa che sta per qualcos'altro, ed è necessario che ci sia un modo per decodificare il simbolo per capire per cosa sta. Nel caso di segni arbitrari non c'è nessuna possibilità di decodifica, e quindi i segni non sono simboli e come osserva giustamente Wittgenstein non possono influenzarci.<br/>
                                Quando la scrittura asemica è usata come la uso io la decodifica non è esplicita, ma possibile. C'è un'intenzione nel mio processo creativo, un'idea dietro l'ordine che può essere scoperta. Questo rende i miei segni asemici simboli. Nella action painting, come ad esempio un dipinto di Pollock, non c'è nessuna idea o ordine, quindi niente da decodificare. Semplicemente è e non sta per qualcos'altro, quindi non può essere un simbolo.<br/>
                                La scrittura asemica invita l'osservatore a decodificare in privato. Questo sforzo attiva la sua immaginazione, che spesso è dormiente, e può chiudere il processo ritornando al messaggio originale, completando l'opera d'arte lui stesso.
                            </p>
                            <p>
                                <i>ES: "La bellezza, sia morale che naturale, è più propriamente sentita, che percepita con l'intelletto" scrive Hume nella parte finale della Ricerca sull'intelletto umano (1748). Nove anni dopo, Hume scrive, "La bellezza non è una qualità appartenente alle cose stesse". La bellezza sentita può essere seguita dalla speranza di renderee la sua impressione indelebile nella mente, corpo, o anima. Sia che sviluppiamo un'affinità om una paura, come Rainer Maria Rilke scrive del terrore nato nella bellezza, si può dire che siamo guidati da quello che crea un senso di bellezza dentro di noi.<br/>
                                Non posso evitare di connettere la bellezza in questo contesto all'intuizione: la sensazione di sapere cosa sia giusto o sbagliato senza prove più certe di un senso interno. L'intuizione è la nostra guida più grande attraverso l'incertezza. Sull'intuizione, Wittgenstein scrive: "Se l'intuizione è una voce interna, come faccio a sapere in che modo debbo obbedirle? E come faccio a sapere che non mi inganna? Perché se può guidarmi per la strada giusta, potrà anche portarmi su una pista falsa." Wittgenstein evidenzia quanto sia difficile ascoltare e seguire la propria intuizione, se è davvero una fonte di conoscenza profonda.<br/>
                                Se la bellezza esiste solo nella mente dell'osservatore, potrebbe l'incertezza avere lo stesso peso? È l'incertezza, come la bellezza, più propriamente sentita che percepita, quindi esercitando la sua influenza più sui sensi che sulla realtà?</i><br/><br/>
                                M: I nostri sensi e la realtà non possono mai essere separati, insieme formano la nostra esperienza, per cui quello che influenza l'uno inevitabilmente influenza l'altro. Non possiamo mai essere in contatto con la nuda realtà, ci arriva sempre attraverso una lente e con un contesto.<br/>
                                L'incertezza in particolare è soggettiva perché pertiene a un giudizio che facciamo inerente a qualche percezione - non siamo sicuri di quello che succederà dopo. Potrebbe essere perché il contesto non è familiare o perché i nostri sensi non stanno ricevendo i dati che si aspettano. Un sentiero mancante che non è parte della realtà stessa ma all'interno della nostra mente.<br/>
                                La bellezza è molto simile, un giudizio che facciamo inerente uno specifico oggetto o esperienza. Nonostante ci possa essere una qualche proprietà generica che è spesso associata con la bellezza, io credo che sia il risultato delle nostre esperienze precedenti. Ci viene insegnato dall'inizio della nostra vita cos'è bello e cosa no, e il nostro concetto di bellezza evolve in risposta alle nostre esperienze.
                            </p>
                            <p>
                                <i>ES: Qual è il suono di una mente quando passa da incertezza a certezza?</i><br/><br/>
                                M:
                            </p>
                            <img
                                src='/images/varsemic/varsemic_3.svg'
                                alt='evolution' className="pieceImgWide"
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}