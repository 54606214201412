import React, { Component } from "react";

import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import ScrollToTop from "./components/ScrollToTop";

import Journey from './pages/Journey'
import E28 from "./pages/E28";
import Ouroboros from "./pages/Ouroboros";
import People from "./pages/People";
import Raw from "./pages/Raw";
import Capsules from "./pages/Capsules";
import Divergence from "./pages/Divergence";
import Roads from "./pages/Roads";
import Disegni from "./pages/Disegni";
import EnGoesBack from "./pages/EnGoesBack";
import Scelte from "./pages/Scelte";
import Sentieri from "./pages/Sentieri";
import YourStory from "./pages/YourStory";
import OnWriting from "./pages/OnWriting";
import MarketPiece from "./pages/MarketPiece";
import PointLineCode from "./pages/PointLineCode";
import NeverendingConversations from "./pages/NeverendingConversations";
import Manifesto from "./pages/Manifesto";
import Chronicles from "./pages/Chronicles";
import Loss from "./pages/Loss";
import SomeFormOfPoetry from "./pages/SomeFormOfPoetry"
import SystemsOfSelf from "./pages/SystemsOfSelf"
import Storylines from "./pages/Storylines";
import Indaco from "./pages/Indaco";
import SubPoetry from "./pages/SubPoetry";
import Linebreak from "./pages/Linebreak";
import Evolution from "./pages/Evolution";
import HomeV2 from "./pages/HomeV2";
import Ricercasemica from "./pages/Ricercasemica";
import Carattere from "./pages/Carattere";
import Taccuini from "./pages/Taccuini";
import VarSemic from "./pages/VarSemic";
import VarSemicIT from "./pages/VarSemicIT";

import EndlessDialogue from "./pages/EndlessDialogue";

class App extends Component {
  render() {
    return (
      <Router>
        <ScrollToTop />
        <div>
          <Routes>
            <Route exact path="/" element={<HomeV2 />}/>
            <Route path="/journey" element={<Journey />}/>
            <Route path="/e28" element={<E28 />}/>
            <Route path="/ouroboros" element={<Ouroboros />}/>
            <Route path="/people" element={<People />}/>
            <Route path="/disegni" element={<Disegni />}/>
            <Route path="/corpus/:tokenId" element={<MarketPiece />}/>
            <Route path="/raw" element={<Raw />}/>
            <Route path="/capsules" element={<Capsules />}/>
            <Route path="/divergence" element={<Divergence />}/>
            <Route path="/onwriting" element={<OnWriting />}/>
            <Route path="/roads" element={<Roads />}/>
            <Route path="/engoesback" element={<EnGoesBack />}/>
            <Route path="/scelte" element={<Scelte />}/>
            <Route path="/sentieri" element={<Sentieri />}/>
            <Route path="/yourstory" element={<YourStory />}/>
            <Route path="/pointlinecode" element={<PointLineCode />}/>
            <Route path="/neverendingconversations" element={<NeverendingConversations />}/>
            <Route path="/endlessdialogue" element={<EndlessDialogue />}/>
            <Route path="/systemsofself" element={<SystemsOfSelf />}/>
            <Route path="/manifesto" element={<Manifesto />}/>
            <Route path="/chronicles" element={<Chronicles />}/>
            <Route path="/loss" element={<Loss />}/>
            <Route path="/someformofpoetry" element={<SomeFormOfPoetry />}/>
            <Route path="/storylines" element={<Storylines />}/>
            <Route path="/subpoetry" element={<SubPoetry />}/>
            <Route path="/linebreak" element={<Linebreak />}/>
            <Route path="/taccuini" element={<Taccuini />}/>
            <Route path="/indaco" element={<Indaco />}/>
            <Route path="/evolutionv0" element={<Evolution />}/>
            <Route path="/ricercasemica" element={<Ricercasemica />}/>
            <Route path="/carattere" element={<Carattere />}/>
            <Route path="/itvarsemic" element={<VarSemicIT />}/>
            <Route path="/varsemic" element={<VarSemic />}/>
            <Route path="/home" element={<HomeV2 />}/>
          </Routes>
        </div>
      </Router>
    );
  }
}

export default App;
